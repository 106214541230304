.button {
  background-color: rgba(0, 0, 0, 0.15);
  font-weight: 600;
  width: 100%;
}

.button,
.input {
  border: 0;
  padding: 1rem;
}

.form {
  padding: 1rem;
  width: 100%;
}

.field {
  display: block;
  margin: 0 0 1rem 0;
}

.input {
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%;
}

.label {
  display: block;
  margin-bottom: 0.5rem;
}
