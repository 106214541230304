.button {
  background-color: rgba(0, 0, 0, 0.15);
  font-weight: 600;
  width: 100%;
}

.button,
.input {
  border: 0;
  padding: 1rem;
}

.form {
  margin: 1rem;
}

.field {
  display: block;
  margin: 0 0 1rem 0;
}

.input {
  background-color: rgba(0, 0, 0, 0.05);
}

@media (min-width: 768px) {
  .button {
    width: inherit;
  }

  .field {
    display: inline-block;
    margin: 0 1rem 0 0;
  }
}
