.app {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  width: 100%;
}

.title {
  font-family: "ZCOOL KuaiLe", cursive;
  font-size: 10vw;
  font-weight: normal;
  margin: 0;
  position: relative;
}

@media (max-width: 768px) {
  .title {
    font-size: 15vw;
  }
}
