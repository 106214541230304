:root {
  --xs: (max-width: 575.98px);
  --min-sm: (min-width: 576px);
  --sm: --min-sm and (max-width: 767.98px);
  --min-md: (min-width: 768px);
  --md: --min-md and (max-width: 991.98px);
  --min-lg: (min-width: 992px);
  --lg: --min-lg and (max-width: 1199.98px);
  --xl: (min-width: 1200px);
}

body,
html {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

* {
  box-sizing: border-box;
}

.App_app__3cuHJ {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  width: 100%;
}

.App_title__27a-7 {
  font-family: "ZCOOL KuaiLe", cursive;
  font-size: 10vw;
  font-weight: normal;
  margin: 0;
  position: relative;
}

@media (max-width: 768px) {
  .App_title__27a-7 {
    font-size: 15vw;
  }
}

.Polygon_content__3cZiT {
  position: relative;
}

.Polygon_polygon__1_hXm {
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.Login_button__2iCkd {
  background-color: rgba(0, 0, 0, 0.15);
  font-weight: 600;
  width: 100%;
}

.Login_button__2iCkd,
.Login_input__1Us-3 {
  border: 0;
  padding: 1rem;
}

.Login_form__3gGwT {
  margin: 1rem;
}

.Login_field__39ui9 {
  display: block;
  margin: 0 0 1rem 0;
}

.Login_input__1Us-3 {
  background-color: rgba(0, 0, 0, 0.05);
}

@media (min-width: 768px) {
  .Login_button__2iCkd {
    width: inherit;
  }

  .Login_field__39ui9 {
    display: inline-block;
    margin: 0 1rem 0 0;
  }
}

.Register_button__2jK9C {
  background-color: rgba(0, 0, 0, 0.15);
  font-weight: 600;
  width: 100%;
}

.Register_button__2jK9C,
.Register_input__1DDqK {
  border: 0;
  padding: 1rem;
}

.Register_form__WbouA {
  padding: 1rem;
  width: 100%;
}

.Register_field__BZ5Gp {
  display: block;
  margin: 0 0 1rem 0;
}

.Register_input__1DDqK {
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%;
}

.Register_label__16dYW {
  display: block;
  margin-bottom: 0.5rem;
}

