:root {
  --xs: (max-width: 575.98px);
  --min-sm: (min-width: 576px);
  --sm: --min-sm and (max-width: 767.98px);
  --min-md: (min-width: 768px);
  --md: --min-md and (max-width: 991.98px);
  --min-lg: (min-width: 992px);
  --lg: --min-lg and (max-width: 1199.98px);
  --xl: (min-width: 1200px);
}
